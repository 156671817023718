import React, { FC } from "react"
import Styles from "./LPCases.module.scss";
import PostItem from "../components/postItem";
import { post1, post2, post3 } from "./const"
import { PostTimeLineType } from "../types/models/Post";

type ContentItemProps = {
    content: contentType
    index: number
}

const ContentItem: FC<ContentItemProps> = ( {
    content,
    index
} ) =>
    <li className={ Styles.content_container } key={ content.title }>
        <div className={ Styles.slash }></div>
        <span className={ Styles.number }>{ index + 1 }</span>
        <h3>
            { content.title }
        </h3>
        <div className={ Styles.description }>
            { content.description }
        </div>
        <p className={ Styles.exampleLinkList }>
            <PostItem post={ content.examplePost } />
        </p>
        <div className={ Styles.circle }></div>
    </li>

type contentType = {
    title: string
    sentence: string
    examplePost: PostTimeLineType
    description: JSX.Element
}

const contentMenuList: contentType[] = [
    {
        title: "練習日記",
        sentence: "実際の練習日記の例はこちら",
        examplePost: post1,
        description:
            <p>
                <span>練習の進捗や調子を</span>
                <span className={ Styles.emphasis }>動画つきの練習日記</span>
                <span>として蓄積、共有できます</span>
            </p>
    },
    {
        title: "練習のハイライト作成",
        sentence: "実際のフォローアップの例はこちら",
        examplePost: post2,
        description:
            <p>
                <span>選手全員の練習を全体で<span className={ Styles.emphasis }>振り返り、教え合う</span>ことができます。</span>
            </p>
    },
    {
        title: "練習コンテンツ作成",
        sentence: "実際の練習コンテンツ例はこちら",
        examplePost: post3,
        description:
            <p>
                <span className={ Styles.emphasis }>チーム内で共有したい練習メニュー</span>
                <span>の作成が簡単にできます。</span>
            </p>
    },
]

const LPCases = () =>

    <div className={ Styles.container } id="cases">
        <h2 className={ Styles.service_summary }>ご利用例</h2>
        <p className={ Styles.under_heading }>Cases</p>
        <div style={ { textAlign: "center" } }>
            <p className={ Styles.service_summary_description }>
                <span className={ Styles.emphasis }>動画練習日記</span>
                <span>や、コーチによる練習後の</span>
                <span className={ Styles.emphasis }>細かなフォローアップ</span>
                <span>、</span>
                <br />
                <span>コーチの</span><span className={ Styles.emphasis }>練習コンテンツ作り</span>
                <span>に活用できます</span>
                <br />
            </p>
        </div>
        <ul className={ Styles.content_list_container }>
            { contentMenuList.map( ( content, index ) =>
                <ContentItem
                    content={ content }
                    index={ index }
                    key={ content.title } />
            ) }
        </ul>
    </div>

export default LPCases
