import { Link } from "gatsby"
import React, { FC, useMemo } from "react"
import Styles from "../styles/post_item.module.scss";
import getNewPathAvoidingCache from "../functions/getNewPathAvoidingCache"
import { getHowOld } from "../functions/getHowOld";
import { FileType, PostTimeLineType } from "../types/models/Post";
import { UserIcon as RowUserIcon } from "../icons/UserIcon"
import clsx from "clsx"
import { CommentRoundIcon as CommentIcon } from "../icons/CommentRoundIcon";

const iconStyle = { width: 48, height: 48, borderRadius: "50%", border: "4px solid white", borderBottomLeftRadius: 0, borderBottomColor: "rgba( 0, 0, 0, 0 )", backgroundColor: "white" }

const UserIcon = ( {
    src
}: { src?: string } ) =>
    <div style={ { display: "inline-block", position: "absolute", top: -38 } }>
        { src ?
            <img src={ src } style={ iconStyle } />
            :
            <div className={ Styles.default_avator } style={ { ...iconStyle, display: "inline-block", position: "relative" } }>
                <RowUserIcon style={ { color: "#aaa", fontSize: 18, position: "absolute", top: "50%", left: "50%", transform: "translate3d(-50%, -50%, 0)" } } />
            </div>
        }
    </div >

type Props = {
    post: PostTimeLineType,
    userIconVisible?: boolean
    showUserName?: boolean
    onLPPostList?: boolean
}

const PostItem: FC<Props> = ( {
    post,
    userIconVisible = true,
    showUserName = false,
    onLPPostList = false
} ) => {
    const { text, file_type, file, thumbnail,
        comment_number, output_author, pk, created_at } = post

    const ImageMemo = useMemo( () =>
        file_type === FileType.VIDEO ?
            <img
                className={ Styles.thumbnail }
                onError={ e => getNewPathAvoidingCache( e.target as HTMLImageElement ) }
                src={ thumbnail } /> :
            file_type === FileType.IMAGE ?
                <img className={ Styles.thumbnail } src={ file + "?a" } /> :
                <div className={ Styles.thumbnail }></div>
        , [ thumbnail, file ] )


    return useMemo( () =>
        <Link
            to={ `/post/${ post.pk }` }
            state={ { postID: pk, post } }
            className={ clsx( Styles.post_item, onLPPostList && Styles.LP_post_item ) }>
            <div className={ Styles.container }>
                { ImageMemo }
                <div className={ Styles.text_container }>
                    { userIconVisible &&
                        <UserIcon src={ output_author ? output_author.profile_image_100 : undefined } /> }
                    { userIconVisible && showUserName &&
                        <span className={ Styles.user_name }>{ output_author?.name }</span>
                    }
                    <div className={ Styles.text_wrapper }>
                        <p className={ Styles.text }>
                            { text }
                        </p>
                    </div>
                    <p className={ Styles.info_wrapper }>
                        <CommentIcon className={ Styles.comment_icon } />
                        <span className={ Styles.comment_number }>{ comment_number }</span>
                        { getHowOld( created_at ) }
                    </p>
                </div>
            </div>
        </Link>, [ post ] )
}



export default PostItem
