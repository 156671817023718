import React, { FC } from "react"
import Styles from "./LPFeature.module.scss";
import Image from "../components/image"
import MediaQuery from "react-responsive";
import clsx from "clsx"

type ContentItemProps = {
    content: contentType,
    index: number
}

const ContentItem: FC<ContentItemProps> = ( {
    content,
    index
} ) =>
    <li className={ Styles.content_container } key={ content.title }>
        <div className={ Styles.explanation_container }>
            <div className={ Styles.content } style={ { order: ( index + 1 ) % 2, margin: "auto" } }>
                <div style={ { position: "relative" } }>
                    <h3 data-text={ content.EnglishTitle }>
                        {/* { content.icon &&
                            <FontAwesomeIcon icon={ content.icon } className={ Styles.icon } /> } */}
                        { content.title }
                    </h3>
                    <div className={ Styles.description }>
                        { content.description }
                        <div className={ Styles.description_bg }></div>
                    </div>
                </div>
            </div>
            <MediaQuery query="(max-width: 899px)">
                <Image filename={ content.image } style={ { maxHeight: "60vh" } } />
            </MediaQuery>
            <MediaQuery query="(min-width: 900px)">
                <Image filename={ content.image } style={ { maxHeight: "60vh", width: "50%", order: index % 2 } } />
            </MediaQuery>
        </div>
        <div className={ clsx( index % 2 ? Styles.bottom_circle : Styles.circle ) } ></div>
    </li >

type contentType = {
    title: string
    EnglishTitle: string
    icon: null
    image: string
    description: JSX.Element
}


const contentMenuList: contentType[] = [
    {
        title: "蓄積 & 検索",
        EnglishTitle: "Accumulate & Search",
        // icon: faDatabase, 
        icon: null,
        image: "top_mock/top_search.png",
        description:
            <p>
                <span>タグやキーワードで検索ができるので、見たい動画が</span>
                <span className={ Styles.emphasis }>すぐに見つかります。</span>
                <br /><br />
                <span>他選手の</span>
                <span className={ Styles.emphasis }>昔の練習からたくさん学べます。</span>
            </p>
    },
    // {title:"コツをチーム内に瞬時に浸透", icon:faUsers, image:"",
    //     description:"投稿範囲を「チーム内」にすれば、チームメンバー全員が投稿を見られます。\n"},
    {
        title: "動画比較 & 描き込み",
        EnglishTitle: "Compare & Draw",
        icon: null,
        image: "top_mock/top_compare.png",
        description:
            <p>
                <span>動画比較機能で、上手な選手の動画と</span>
                <span className={ Styles.emphasis }>見比べられます。</span>
                <br /><br />
                <span>また、動画のキャプチャに図を描き込んでコメントができるので</span>
                <span className={ Styles.emphasis }>分かりやすいアドバイス</span>
                <span>が可能です。</span>
            </p>
    },
]

const LPFeature = () =>
    <div className={ Styles.container } id="feature">
        <div className={ Styles.header_wrapper }>
            <h2 className={ Styles.service_summary }>サービス概要と特徴</h2>
            <p className={ Styles.under_heading }>Service and Feature</p>
            <div style={ { textAlign: "center", position: "relative" } }>
                <div className={ Styles.heading_circle }></div>
                <p className={ Styles.service_summary_description }>
                    <span>ClubCloudは、</span>
                    <span className={ Styles.emphasis }>スポーツチーム用動画ストレージサービス</span>
                    <span>です。</span>
                    <br />
                    <span>練習中の</span><span className={ Styles.emphasis }>意識やコツ、練習法</span>
                    <span>などをチームで気軽に共有・蓄積できます。</span>
                    <p style={ { marginTop: 2 } }>{ "注) チーム、個人関わらず月の動画アップロード上限は10000秒になります" }</p>
                    <br />
                </p>
            </div>
        </div>
        <ul className={ Styles.content_list_container }>
            { contentMenuList.map( ( content, index ) =>
                <ContentItem content={ content } key={ content.title } index={ index } />
            ) }
        </ul>
    </div>

export default LPFeature
