import React, { useState, useEffect, useRef } from "react"
import Styles from "./LPHeader.module.scss";
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CloseIcon } from "../icons/CloseIcon";

const haeaderColoredScrollThreshold = 80 //これ以上スクロールすると、headerに色がつく

const LPHeader = () => {
    const [ drawerOpen, setDrawerOpen ] = useState( false )
    const [ headerIsScrolled, setHeaderIsScrolled ] = useState( false )
    const headerIsScrolledRef = useRef( headerIsScrolled )

    useEffect( () => {
        document.addEventListener( "scroll", toggleHeader )
        return () => document.removeEventListener( "scroll", toggleHeader )
    }, [] )

    useEffect( () => {
        headerIsScrolledRef.current = headerIsScrolled
    }, [ headerIsScrolled ] )

    const toggleHeader = () => {
        if ( document.documentElement.scrollTop < haeaderColoredScrollThreshold ) {
            headerIsScrolledRef.current && setHeaderIsScrolled( false )
        }
        else {
            setHeaderIsScrolled( true )
        }
    }

    const scrollToContent = ( contentID: string ) => {
        const content = document.getElementById( contentID )
        if ( !content ) return
        let featureY = content.getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const buffer = 40
        const top = featureY + offsetTop - buffer
        window.scrollTo( {
            top,
            behavior: "smooth"
        } );
    }

    return (
        <header className={ `${ Styles.header } ${ headerIsScrolled ? Styles.scrolled_header : "" }` }>
            <h1 className={ Styles.site_name }>ClubCloud</h1>
            <label className={ Styles.drawer_label } htmlFor="drawer_input" onClick={ () => setDrawerOpen( true ) }>
                <div></div>
                <div></div>
                <div></div>
            </label>
            <nav className={ Styles.menu } style={ drawerOpen ? { transform: "translate3d(0,0,0)" } : {} } onClick={ () => setDrawerOpen( false ) }>
                <ul>
                    <li className={ Styles.menu_close_icon }>
                        <CloseIcon style={ { fontSize: "1.4rem" } } />
                    </li>
                    <li>
                        <a href="" aria-disabled onClick={ ( e ) => {
                            e.preventDefault()
                            scrollToContent( "feature" )
                        } }>
                            <span>概要と特徴</span>
                            <span>Service</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={ ( e ) => {
                            e.preventDefault()
                            scrollToContent( "cases" )
                        } }>
                            <span>ご利用例</span>
                            <span>Cases</span>
                        </a>
                    </li>
                    <li>
                        <Link to="/contact">
                            <span>お問い合わせ</span>
                            <span>Contact</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/login">
                            <span>ログイン</span>
                            <span>Login</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/registration">
                            <span>新規登録</span>
                            <span>Registration</span>
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className={ Styles.drawer_background } style={ drawerOpen ? {} : { display: "none" } }></div>
        </header>
    )
}

export default LPHeader
