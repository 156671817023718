const getNewPathAvoidingCache = ( target: HTMLImageElement | HTMLVideoElement ) => {

    if ( target.src.includes( "?" ) ) return

    let intervalID = setInterval( () => returnAnotherPath(), 2000 )

    setTimeout( () => clearInterval( intervalID ), 10000 )

    const returnAnotherPath = () => {
        if ( target.src.includes( "#t=" ) ) {
            let videoStartTime = target.src.split( "#t=" ).slice( -1 )[ 0 ]
            let src = target.src.split( "#t=" )[ 0 ]
            target.src = src + "?" + new Date().getTime() + "#t=" + videoStartTime
            return
        }
        target.src = target.src + "?" + new Date().getTime()
    }
}

export default getNewPathAvoidingCache

// const alreadyCountExistsInURL = URL.includes( `${ countParamName }=` )
// alreadyCountExistsInURL ?
//     setURLwithIncrement() :
//     setURLWith0()

// const setURLWith0 = () =>
//     URL.includes( "?" ) ?
//         setURL( URL + `&${ countParamName }=0` ) :
//         setURL( URL + `?${ countParamName }=0` )

// const setURLwithIncrement = () => {
//     const countStr = URL.split( `${ countParamName }=` ).slice( -1 )[ 0 ]
//     console.warn( "countStr", countStr )
//     const count = Number( countStr )
//     console.warn( "count", count )
//     if( count == 5 ) return
//     const newURLBase = URL.split( `${ countParamName }=` ).slice( 0, -1 ).join( "" )
//     const newURL = newURLBase + `${ countParamName }=${ count + 1 }`
//     console.warn( "newURL", newURL )
//     setURL( newURL )
// }
