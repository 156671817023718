import { FileTypeType } from "../types/models/Post";

export const post1 = {
    "pk": "b82ff447-74c6-4131-95ea-52657d021a8c",
    "text": "後ろ降り上がり倒立(屈腕)成長記録",
    "file_type": 1 as FileTypeType,
    "file": "https://d2ml46mqa54h0p.cloudfront.net/media/dd433564-cbed-4081-9af0-69c911b92018/file/VID_20190824_175645_s016ce5241b-ca70-4de8-aea7-48b843022fc9.mp4",
    "thumbnail": "https://d2ml46mqa54h0p.cloudfront.net/media/dd433564-cbed-4081-9af0-69c911b92018/thumbnail/VID_20190824_175645_s016ce5241b-ca70-4de8-aea7-48b843022fc9_00001.jpg",
    "output_author": {
        "name": "わたなべひであき",
        "pk": "b81bf060-9c85-4553-ae91-ab988580cb8d",
        "team": "大阪大学体操部",
        "status_message": "",
    },
    "created_at": "2019-11-08T12:01:26.401000+09:00",
    "uuid": "b82ff447-74c6-4131-95ea-52657d021a8c",
    "comment_number": 4,
    "how_old": ""
}

export const post2 = {
    "pk": "3b9f8868-fe05-4d33-8062-ec925f739c59",
    "text": "ハイライト2020.02.15",
    "file_type": 2 as FileTypeType,
    "file": "https://d2ml46mqa54h0p.cloudfront.net/media/dd433564-cbed-4081-9af0-69c911b92018/file/8aa27778-abc1-447f-93cc-6d16f54d0b6ce60465d3-9ad2-4cd0-84e3-e9ef13a8520b.jpg",
    "output_author": {
        "name": "片山潤",
        "pk": "973995bc-7660-4ecd-aa84-6d00fcb528df",
        "team": "大阪大学体操部",
        "status_message": "",
        "profile_image_100": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/c9fbb1b58220062c7122af21d9842d2b.jpg",
        "profile_image_400": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/033238834d63b889581765912546a4b1.jpg"
    },
    "created_at": "2020-02-14T22:28:27.737348+09:00",
    "uuid": "3b9f8868-fe05-4d33-8062-ec925f739c59",
    "comment_number": 17,
    "how_old": ""
}

export const post3 = {
    "pk": "1b707d3d-3cd6-42d6-8715-6a245eb225bd",
    "text": "男子2019基礎アップ",
    "file_type": 2 as FileTypeType,
    "file": "https://d2ml46mqa54h0p.cloudfront.net/media/4/file/%E7%94%B7%E5%AD%90%E3%82%A2%E3%83%83%E3%83%97.png",
    "output_author": {
        "name": "片山潤",
        "pk": "973995bc-7660-4ecd-aa84-6d00fcb528df",
        "team": "大阪大学体操部",
        "status_message": "",
        "profile_image_100": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/c9fbb1b58220062c7122af21d9842d2b.jpg",
        "profile_image_400": "https://d2ml46mqa54h0p.cloudfront.net/media/CACHE/images/profile_image/16/10322f04-7a03-43cb-ac16-a9c5505fd618/033238834d63b889581765912546a4b1.jpg"
    },
    "type": "public",
    "created_at": "2019-11-16T00:11:26.840000+09:00",
    "uuid": "1b707d3d-3cd6-42d6-8715-6a245eb225bd",
    "comment_number": 5,
    "how_old": ""
}
