import React, { useState, useEffect } from "react"
import Styles from "./LPPostList.module.scss";
import PostItem from "../components/postItem"
import { PostTimeLineType } from "../types/models/Post";
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage"
import client from "../apis/client";

const LPPostList = () => {

    const [ postList, setPostList ] = useState<PostTimeLineType[]>( getInitialStateFromSessionStorage( "topPagePostList", [] ) )
    const [ showAllPost, setShowAllPost ] = useState<boolean>( getInitialStateFromSessionStorage( "topPageShowAllPost", false ) )

    useEffect( () => {
        client.get( "/api/public_post/?comment_number=2" )
            .then( response => {
                setPostList( response.data )
                sessionStorage.setItem( `topPagePostList`, JSON.stringify( response.data ) )
            } )
            .catch( error => console.log( error ) )
    }, [] )

    useEffect( () => {
        sessionStorage.setItem( `topPageShowAllPost`, JSON.stringify( showAllPost ) )
    }, [ showAllPost ] )

    return (
        <div className={ Styles.container }>
            <h2 className={ Styles.service_summary }>注目のチーム外投稿</h2>
            <p className={ Styles.under_heading }>Featured Posts</p>
            <p className={ Styles.service_summary_note }>※各動画の共有設定は「チーム外」、「限定公開」、「チーム内」、「個人」から選べます</p>
            <div style={ { position: "relative" } }>
                <ul className={ Styles.post_list } style={ showAllPost ? {} : { maxHeight: 500 } }>
                    { postList.map( post =>
                        <PostItem
                            key={ post.pk }
                            onLPPostList={ true }
                            post={ {
                                ...post,
                                // how_old: <span style={ { width: 4, marginLeft: -12 } }></span>
                            } } />
                    ) }
                </ul>
                { showAllPost ||
                    <div className={ Styles.more_post } onClick={ () => setShowAllPost( true ) }>
                        <p style={ { textAlign: "center" } }>もっと見る</p>
                    </div> }
            </div>
        </div>
    );
}

export default LPPostList
