import React from "react"
import Styles from "./LPFooter.module.scss";
import { navigate } from "gatsby";


const FooterInfoList = [
    {
        label: "料金",
        list: [
            { label: "ただいま完全無料でお使いいただけます", func: () => { } }
        ]
    },
    {
        label: "重要事項",
        list: [
            // { label : "利用規約", func : ()=>navigate("/terms") },
            { label: "プライバシーポリシー", func: () => navigate( "/privacy_policy" ) },
            { label: "利用規約", func: () => navigate( "/terms" ) },
        ]
    },
    {
        label: "支援者一覧",
        list: [
            { label: "クラウドファンディング支援者", func: () => navigate( "/cf_supporter_list" ) }
        ]
    }
]

// const handleKeyDown = ( ev, func ) => {
//     if( ev.keyCode == 13 ) {
//         func()
//     }
// }


const LPFooter = () =>
    <footer className={ Styles.footer }>
        <div className={ Styles.container }>
            <div className={ Styles.coorporate_info_container }>
            </div>
            <ul className={ Styles.footer_info_list }>
                { FooterInfoList.map( ( infoList, index ) =>
                    <li key={ index }>
                        <p>{ infoList.label }</p>
                        <ul className={ Styles.info_list }>
                            { infoList.list.map( info =>
                                <li 
                                    className={Styles.footer_item}
                                    tabIndex={0} 
                                    onClick={ () => info.func() } 
                                    key={ info.label }>
                                    <span>{ info.label }</span>
                                </li>
                            ) }
                        </ul>
                    </li>
                ) }
            </ul>
            <div className={ Styles.copyright_container }>
                <p><small>&copy; 2019 ClubCloud</small></p>
            </div>
        </div>
    </footer >

export default LPFooter
