import { CommentType } from "./Comment"
import { OutputAuthorType, TimeLineAutor } from "./User"
import { TagType } from "./Tag"
import { SelectButtonType } from "./SelectButton"
import { Overwrite } from "../utils";
import { PickedMediaType } from "./Media/Media";
import { ConfigType } from "./Config";
import { ReactionGetType } from "./Reaction";

export const FileType = {
    TEXT: 0,
    VIDEO: 1,
    IMAGE: 2,
} as const
export type FileTypeType = typeof FileType[ keyof typeof FileType ]

export const PublishType = {
    PUBLIC: "public" as const,
    LIMITEDLY_PUBLIC: "limitedly_public" as const,
    TIMELINE: "timeline" as const,
    MYOWN: "myown" as const,
}
const PublishTypeList = [ PublishType.PUBLIC, PublishType.LIMITEDLY_PUBLIC, PublishType.TIMELINE, PublishType.MYOWN ]
export type PublishTypeType = typeof PublishTypeList[ number ]

export type PostDetailType = {
    pk: string;
    text: string;
    file_type: FileTypeType;
    thumbnail?: string;
    file?: string;
    output_author: OutputAuthorType;
    is_real_name: boolean;
    comment_is_anonymous: boolean;
    type: PublishTypeType;
    community: number;
    comment_list: CommentType[];
    tag_list: TagType[];
    mytag_list: TagType[];
    created_at: string;
    is_liked: boolean;
    is_mine: boolean;
    belong_to_the_same_team_as_request_user: boolean;
    commentable: boolean;
    can_upload_video: boolean;
    uuid: string;
    reactions: ReactionGetType[]
    team_post_fix_uuid: string | null
}

export type TextPostType = Overwrite<PostTimeLineType | PostDetailType, {
    file: undefined
    thumbnail: undefined
}>
export type VideoPostType = Overwrite<PostTimeLineType | PostDetailType, {
    file: string
    thumbnail: string
}>
export type ImagePostType = Overwrite<PostTimeLineType | PostDetailType, {
    file: string
    thumbnail: undefined
}>

export const isPostNotUndefined = ( post: any ): post is PostTimeLineType | PostDetailType | VideoPostType | ImagePostType => !!post
export const isDetailPost = ( post: any ): post is PostDetailType => post && !!post.comment_list
export const isTextPost = ( post: any ): post is TextPostType => post && post.file_type === FileType.TEXT
export const isVideoPost = ( post: any ): post is VideoPostType => post && post.file_type === FileType.VIDEO
export const isImagePost = ( post: any ): post is ImagePostType => post && post.file_type === FileType.IMAGE



export type PostTimeLineType = {
    text: string;
    originalText?: string
    pk: string;
    how_old: string;
    file_type: FileTypeType;
    file?: string;
    thumbnail?: string;
    comment_number: number;
    created_at: string;
    output_author: TimeLineAutor;
    is_liked?: boolean // まぁないけど
    commentable?: boolean // まぁないけど
    can_upload_video?: boolean// まぁないけど
    comment_is_anonymous?: boolean // まぁないけど
    is_mine?: boolean // まぁないけど
}

export const ObjectName = {
    COMMENT: "comment",
    POST: "post"
} as const
export type ObjectNameType = typeof ObjectName[ keyof typeof ObjectName ]

export type IconDictType = {
    [ key in PublishTypeType ]: JSX.Element
}

export type DraftType = {
    text: string
    media?: PickedMediaType
    tagList: string[]
    myTagList: string[]
    rangeOptionList?: SelectButtonType<string>[]
    configList: ConfigType[]
    ID: DraftIDType
}

export type DraftIDType = string

export type OptionalType<T, K> = T & ( PostDetailType | { [ K in keyof K ]?: never } )
export type PostType = PostTimeLineType & ( PostDetailType | { [ K in keyof PostDetailType ]?: never } )


// type SubNavigator<T> = {
//     [ K in keyof T ]: { screen: K; params?: T[ K ] };
// }[ keyof T ];

// export type RootStackParamList = {
//     AuthLoading: { loading: boolean };
//     Auth: undefined;
//     JoinGroupDetail: undefined;
//     CreateProfileModal: undefined;
//     App: SubNavigator<AppParamList>;
// };

// export type AppParamList = {
//     Main: SubNavigator<MainTabParamList>;
//     CreateGroupModal: SubNavigator<CreateGroupModalParamList>;
//     ProfileDetailModal: SubNavigator<ProfileDetailModalParamList>;
//     CreateProfileModal: SubNavigator<CreateProfileModalParamList>;
//     EditGroupModal: SubNavigator<EditGroupModalParamList>;
//     EditProfileModal: SubNavigator<EditProfileModalParamList>;
//     PostModal: SubNavigator<PostModalParamList>;
//     MypageModal: SubNavigator<MypageModalParamList>;
//     BookmarkModal: SubNavigator<BookmarkModalParamList>;
//     InviteGroupModal: SubNavigator<InviteGroupModalParamList>;
// };

// export type MainTabParamList = {
//     Home: SubNavigator<HomeStackParamList>;
//     Search: SubNavigator<SearchStackParamList>;
//     Bookmark: SubNavigator<BookmarkStackParamList>;
//     Notification: SubNavigator<NotificationStackParamList>;
// };

// export type HomeStackParamList = {
//     MainHome: { selectedGroupId?: string };
//     GroupList: undefined;
//     ProfileDetailModal: undefined;
//     GroupHomeModal: SubNavigator<GroupHomeModalParamList>;
//     ArticleStampMemberList: undefined;
//     CommentStampMemberList: undefined;
//     MypageStack: SubNavigator<MypageModalParamList>;
//     OrderGroup: SubNavigator<OrderGroupModalParamList>;
// } & ArticleDetail;

// export type SearchStackParamList = {
//     Search: undefined;
//     SearchedByTag: {
//         pressedTag?: string;
//         activeNarrowIndex?: number;
//     };
// } & ArticleDetail;

// export type BookmarkStackParamList = {
//     MainBookmark: { initialIndex?: number };
//     BookmarkFeed: {
//         bookmarkFolderId: string;
//         isMine?: boolean;
//     };
//     BookmarkFolderDetail: { bookmarkFolderId: string };
//     ProfileDetailModal: undefined;
//     GroupHomeModal: SubNavigator<GroupHomeModalParamList>;
//     ArticleStampMemberList: undefined;
//     CommentStampMemberList: undefined;
//     MypageStack: SubNavigator<MypageModalParamList>;
// } & ArticleDetail;

// // 
// type NavigationProps = StackNavigationProp<
//     EditGroupModalParamList,
//     'InviteSetting'
// >;
// type RouteProps = RouteProp<EditGroupModalParamList, 'InviteSetting'>;
// type Props = {
//     navigation: NavigationProps;
//     route: RouteProps;
// };

// export const InviteSettingScreen: React.FC<Props> = ( { navigation, route } ) => {
