import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Styles from "../styles/index.module.scss"
import Image from "../components/image"
import SEO from "../components/seo"
import LPHeader from "../LP/LPHeader";
import LPFooter from "../LP/LPFooter";
import MediaQuery from "react-responsive";
import LPFeature from "../LP/LPFeature";
import LPCases from "../LP/LPCases";
import LPPostList from "../LP/LPPostList";
import smoothscroll from 'smoothscroll-polyfill';
import { iOSApplicationURL, AndroidApplicationURL } from "../constant/URL"
import { OutboundLink } from "gatsby-plugin-google-gtag"


if ( ( typeof window !== "undefined" ) && ( typeof document !== "undefined" ) ) {
    smoothscroll.polyfill();
}

const IndexPage = () => {

    useEffect( () => {
        document.getElementsByTagName( "body" )[ 0 ].classList.add( "body_element" )
        return () => document.getElementsByTagName( "body" )[ 0 ].classList.remove( "body_element" )
    }, [] )

    return (
        <div>
            <style jsx>
                { `
                    html::-webkit-scrollbar{
                        display:none !important;
                    }
                `}
            </style>
            <LPHeader />
            <SEO title="ClubCloud | Top" />
            <div className={ Styles.container }>
                <div className={ Styles.top_image_wrapper }>
                    <Image filename="front_top_baseball.jpg" />
                    <h1>
                        <p>チーム内で</p>
                        <p>コツと動画を</p>
                        <p>まとめて共有&蓄積</p>
                        {/* <p>自粛期間中の</p>
                        <p>個人練習共有</p>
                        <br></br>
                        <p>自粛前の練習を</p>
                        <p>忘れないように保存も</p> */}
                    </h1>
                    <div className={ Styles.mock_image_wrapper }>
                        <div>
                            <MediaQuery query="(min-width: 900px)">
                                <Image filename="top_mock/top_mock2.png" style={ { width: "auto", height: "70vh" } } />
                            </MediaQuery>
                            <MediaQuery query="(max-width: 899px)">
                                <Image filename="top_mock/top_mock2.png" style={ { width: "calc( 100vw - 158px )" } } />
                            </MediaQuery>
                        </div>
                        <div className={ Styles.app_icon_container }>
                            <div>
                                <div className={ Styles.app_icons }>
                                    <OutboundLink href={ iOSApplicationURL } className={ Styles.app_icon_wrapper }>
                                        <div className={ Styles.app_icon_wrapper }>
                                            <div className={ Styles.appstore_icon }>
                                            </div>
                                        </div>
                                    </OutboundLink>
                                    <OutboundLink href={ AndroidApplicationURL } className={ Styles.app_icon_wrapper }>
                                        <div className={ Styles.app_icon_wrapper }>
                                            <div className={ Styles.google_play_icon }>
                                            </div>
                                        </div>
                                    </OutboundLink>
                                </div>
                                <p className={ Styles.description_qr_image }>スマホアプリは右のQRコードから</p>
                            </div>
                            <Image className={ Styles.qr_image } filename="QR-lead_app.png" />
                        </div>
                    </div>
                    <div className={ Styles.register_button_wrapper }>
                        <button className={ Styles.register_button } onClick={ () => navigate( "/registration" ) }>無料で新規登録</button>
                    </div>
                </div>
            </div>
            <LPFeature />
            <LPCases />
            <LPPostList />
            <LPFooter />
        </div>
    )
}

export default IndexPage
