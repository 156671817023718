export const getHowOld = (dateStr: string) => {
    const now = new Date()
    const date = new Date( dateStr )

    const diff = now.getTime() - date.getTime();
    // 経過時間をDateに変換
    const elapsed = new Date( diff );

    if( elapsed.getUTCFullYear() - 1970 ) {
        return elapsed.getUTCFullYear() - 1970 + '年前';
    }
    else if( elapsed.getUTCMonth() ) {
        return elapsed.getUTCMonth() + 'ヶ月前';
    }
    else if( 6 < elapsed.getUTCDate() && elapsed.getUTCDate() < 31 ) {
        return Math.floor( elapsed.getUTCDate() / 7 ) + '週間前'
    }
    else if( elapsed.getUTCDate() - 1 ) {
        return elapsed.getUTCDate() - 1 + '日前';
    }
    else if( elapsed.getUTCHours() ) {
        return elapsed.getUTCHours() + '時間前';
    }
    else if( elapsed.getUTCMinutes() ) {
        return elapsed.getUTCMinutes() + '分前';
    }
    else {
        return 'たった今';
    }
}
